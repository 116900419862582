<template>
	<div id="questionEdit" class="el-content">
		<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
			<a-form-item label="分类" required>
				<a-select v-model:value="form.category_id" placeholder="请选择" >
					<a-select-option
						v-for="item in topType"
						:key="item.id"
						:value="item.id">{{item.name}}
					</a-select-option>
					</a-select>
			</a-form-item>
			<a-form-item label="问:" required>
				<a-input v-model:value="form.problem_title"></a-input>
			</a-form-item>
			<a-form-item label="答:" required>
				<a-textarea v-model:value="form.problem_value" placeholder="请输入内容" :rows="6" />
			</a-form-item>
			<a-form-item label="是否显示" >
				<a-radio-group v-model:value="form.problem_status">
					<a-radio :value="1">是</a-radio>
					<a-radio :value="0">否</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="排序" >
				<a-input v-model:value="form.rank"></a-input>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 3 }">
				<a-space>
					<a-button type="primary" @click="saveQuestion()">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import questionModel from '@/api/question.js'
import { useRoute } from 'vue-router'
export default{
	setup(){
		const _d = reactive({
			form:{
				id:0,
				rank:99,
				category_id:'',
				problem_title:'',
				problem_value:'',
				problem_status:1
			},
			topType:[],
		})

		const opt = useRoute().query
		questionModel.getQuestionType(1,999,res=>{
			_d.topType = res
		})
		if( opt.id ){
			questionModel.getProblemDetail(opt.id,Object.keys(_d.form),res=>{
				_d.form = res
			})
		}
		const saveQuestion = ()=> questionModel.addOrEditQuestion(_d.form)

		return {
			...toRefs(_d),
			saveQuestion
		}
	},
}
</script>

<style></style>
